<template>
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4 m-2">
          <h4>Dados pessoais</h4>
        </div>
      </div>
      <div class="row">
        <div v-if="$store.state.fastPessoa.loading" class="col-12 text-center">
          <b-icon
            icon="gear-fill"
            animation="spin"
          /> Carregando dados
        </div>
        <div v-else class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <h6>Informações pessoais</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Primeiro nome</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.nome_razao && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.nome_razao"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.nome_razao"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Sobrenome</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.sobrenome_fantasia && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.sobrenome_fantasia"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.sobrenome_fantasia"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Email</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.sobrenome_fantasia && readonly"
                  :value="$store.state.fastPessoa.email"
                  class="form-control"
                  type="text"
                  readonly
                >
              
                <input
                v-else
                  class="form-control"
                  type="text"
                  v-model="$store.state.fastPessoa.email"
                   @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Data de nascimento</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.datanascimento_abertura && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.datanascimento_abertura.split('-')[2] + '/' + $store.state.fastPessoaReadOnly.datanascimento_abertura.split('-')[1] + '/' + $store.state.fastPessoaReadOnly.datanascimento_abertura.split('-')[0]"
                  readonly
                >
                <input
                  v-else
                  v-model="
                   $store.state.fastPessoa.datanascimento_abertura
                  "
                  class="form-control"
                  type="date"
                  autocomplete="no"
                  @focus="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>CPF</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.cpf_cnpj && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.cpf_cnpj"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.cpf_cnpj"
                  v-mask="[
                    '###.###.###-##',
                    '##.###.###/####-##',
                  ]"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Sexo</label>
              <div class="form-group">
                <div
                  v-if="$store.state.fastPessoaReadOnly.sexo && readonly"                               
                >
                  <input
                    v-if="$store.state.fastPessoaReadOnly.sexo == 'M'"
                    type="text"
                    class="form-control"
                    value="Masculino"
                    readonly
                  >
                  <input
                    v-else-if="$store.state.fastPessoaReadOnly.sexo == 'F'"
                    type="text"
                    class="form-control"
                    value="Feminino"
                    readonly
                  >
                </div>
                <select
                  v-else
                  v-model="$store.state.fastPessoa.sexo"
                  class="form-control"
                  @change="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "                                  
                >
                  <option value="">
                    Indefinido
                  </option>
                  <option value="M">
                    Masculino
                  </option>
                  <option value="F">
                    Feminino
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Nacionalidade</label>
              <img class="mx-2 pb-1" :src="getFlag($store.state.fastPessoa.id_pais_pessoa)">
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.id_pais_pessoa && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.id_pais_pessoa"
                  readonly
                >
                <select
                  v-else
                  v-model="$store.state.fastPessoa.id_pais_pessoa"
                  class="form-control"
                  @change="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "                                  
                >
                  <option v-for="pais in organizaNacionalidade" :key="pais.id_pais" :value=pais.id_pais>
                    {{ pais.nacionalidade }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>RG</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.rg && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.rg"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.rg"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Órgão Emissor</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.orgao_emissor && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.orgao_emissor"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.orgao_emissor"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Naturalidade</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.naturalidade && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.naturalidade"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.naturalidade"
                  class="form-control"
                  type="text"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <h6>Telefone</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>DDI</label>
              <img class="mx-2 pb-1" :src="getFlag($store.state.fastPessoa.id_pais_telefone)">
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.id_pais_telefone && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.id_pais_telefone"
                  readonly
                >
                <select
                  v-else
                  v-model="$store.state.fastPessoa.id_pais_telefone"
                  class="form-control"
                  @change="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "                                  
                >
                  <option v-for="pais in organizaDDI" :key="pais.id_pais" :value=pais.id_pais>
                    {{ pais.iso3 }} +{{ pais.fone }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Telefone</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.telefone && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.telefone"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.telefone"
                  v-mask="[
                    '(##) ####-####',
                    '(##) #####-####',
                  ]"
                  type="text"
                  class="form-control"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <h6>Endereço</h6>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>País</label>
              <img class="mx-2 pb-1" :src="getFlag($store.state.fastPessoa.id_pais_endereco)">
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.pais_endereco && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.id_pais_endereco"
                  readonly
                >
                <select
                  v-else
                  v-model="$store.state.fastPessoa.id_pais_endereco"
                  class="form-control"
                  @change="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "                                  
                >
                  <option v-for="pais in organizaPaises" :key="pais.id_pais" :value=pais.id_pais>
                    {{ pais.nome }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>CEP</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.cep && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.cep"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.cep"
                  v-mask="'#####-###'"
                  type="text"
                  class="form-control"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true;
                    buscaCEPAluno();
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Endereço</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.logradouro && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.logradouro"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.logradouro"
                  type="text"
                  class="form-control"
                  maxlength="100"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Número</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.numero && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.numero"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.numero"
                  type="text"
                  class="form-control"
                  maxlength="10"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Complemento</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.complemento && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.complemento"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.complemento"
                  type="text"
                  class="form-control"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Bairro</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.bairro && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.bairro"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.bairro"
                  type="text"
                  class="form-control"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Cidade</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.cidade && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.cidade"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.cidade"
                  type="text"
                  class="form-control"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Estado</label>
              <div class="form-group">
                <input
                  v-if="$store.state.fastPessoaReadOnly.uf && readonly"
                  type="text"
                  class="form-control"
                  :value="$store.state.fastPessoaReadOnly.uf"
                  readonly
                >
                <input
                  v-else
                  v-model="$store.state.fastPessoa.uf"
                  type="text"
                  class="form-control"
                  maxlength="2"
                  autocomplete="no"
                  @keyup="
                   $store.state.fastPessoa.alteracao_pendente = true
                  "
                >
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div
              v-if="$store.state.fastPessoa.alteracao_pendente"
              class="col-12 text-center"
            >
              <button
                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                @click="alteraPlataformaPessoa()"
              >
                <small>
                  <i
                    class="fa fa-floppy-o"
                    aria-hidden="true"
                  />
                  &nbsp;Salvar alterações
                </small>
              </button>
              <button
                class="btn btn-secondary btn-sm mt-2 ml-2"
                @click.prevent="
                  buscaInfoPessoa(
                    id_pessoa, id_plataforma
                  )
                "
              >
                <small>
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                  />
                  &nbsp;Cancelar
                </small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "FastCadastroPessoa",
    props: {
        readonly: {
            type: Boolean,
            required: false,
        },
        id_pessoa: {
          type: Number,
          required: true,
        },
        id_plataforma: {
          type: Number,
          required: true,
        },
        perfil: {
          type: String,
          required: false
        }
    },
    
    mixins: [methods],
    data: function () {
        return {
        }        
    },
    mounted: function () {   
      if (this.id_pessoa && this.id_plataforma) this.buscaInfoPessoa(this.id_pessoa, this.id_plataforma)
      else
        this.exibeToasty("O identificador da pessoa ou da plataforma não foram informados", "error")      
    },
    computed: {
      organizaNacionalidade(){
        const listaPaises = [...this.$store.state.fastPlataformaDadosPaises].filter(pais => pais.nacionalidade.trim() !== '')
        return listaPaises.sort(this.reornedarArrayObjetos("nacionalidade"))
      },
      organizaDDI(){
          const listaPaises = [...this.$store.state.fastPlataformaDadosPaises]
          return listaPaises.sort(this.reornedarArrayObjetos("iso3"))
      },
      organizaPaises(){
        const listaPaises = [...this.$store.state.fastPlataformaDadosPaises]
        return listaPaises.sort(this.reornedarArrayObjetos("nome"))
      }
    },
    methods: {
      // Dados pessoais do aluno
      async buscaInfoPessoa(id_pessoa, id_plataforma) {
        this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${id_pessoa}&id_plataforma=${id_plataforma}`)
        .then(res => {
          // Verifica JSON
          const [err, obj] = this.safeJsonParse(res);
          if (err) {
            this.exibeToasty("Falha ao interpretar json", "error") 
            console.log('Falha ao interpretar json: ' + err.message);
          } else {
            if (!obj.error) {
              if (obj.datanascimento_abertura)
                obj.datanascimento_abertura = obj.datanascimento_abertura.split(
                  "T"
                )[0];
              else obj.datanascimento_abertura = "";
              if (!obj.nome_razao) obj.nome_razao = "";
              if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
              if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
              if (!obj.cep) obj.cep = "";
              if (!obj.cidade) obj.cidade = "";
              if (!obj.complemento) obj.complemento = "";
              if (!obj.id_pais_endereco) obj.id_pais_endereco = "";
              if (!obj.id_pais_pessoa) obj.id_pais_pessoa = "";
              if (!obj.id_pais_telefone) obj.id_pais_telefone = "";
              if (!obj.logradouro) obj.logradouro = "";
              if (!obj.numero) obj.numero = "";
              if (!obj.naturalidade) obj.naturalidade = "";
              if (!obj.sexo) obj.sexo = "";
              if (!obj.telefone) obj.telefone = "";
              if (!obj.rg) obj.rg = "";
              if (!obj.orgao_emissor) obj.orgao_emissor = "";
              if (!obj.uf) obj.uf = "";
              if (!obj.bairro) obj.bairro = "";

              if (!obj.id_usuario)
                this.exibeToasty(
                  "Não foi possível identificar o id_usuario. Consulte um administrador do sistema.",
                  "error"
                );
              if (!obj.id_pessoa)
                this.exibeToasty(
                  "Não foi possível identificar o id_pessoa. Consulte um administrador do sistema.",
                  "error"
                );
              if (!obj.email)
                this.exibeToasty(
                  "Não foi possível identificar o email do usuário. Consulte um administrador do sistema.",
                  "error"
                );

                this.$store.state.fastPessoa = obj;
                // Não copia por referência
                this.$store.state.fastPessoaReadOnly = Object.assign({}, obj);
              
          } else {
            this.exibeToasty("Erro ao recuperar pessoa", "error") 
          }
          this.$store.state.fastPessoa.loading = false
          }
        })
        .catch(e => {
          this.exibeToasty(e, "error")  
          this.$store.state.fastPessoa.loading = false  
        })
      
      },
      // Busca cep api correio
      async buscaCEPAluno(){
        if (this.$store.state.fastPessoa.cep.length == 9) {
          this.buscaCEPCorreio(this.$store.state.fastPessoa.cep).then(e => {
            if (e.cep){
              this.$store.state.fastPessoa.cep = e.cep;
              this.$store.state.fastPessoa.logradouro = e.logradouro;
              this.$store.state.fastPessoa.bairro = e.bairro;
              this.$store.state.fastPessoa.cidade = e.cidade;
              this.$store.state.fastPessoa.uf = e.uf;
            } else {
              this.exibeToasty("CEP não encontrado", "error")
            }
          }).catch(e => this.exibeToasty(e, "error"))
        }      
      },
      async alteraPlataformaPessoa() {
        this.getFastHoraServidor()
        // Validação
        .then(() => {
          let erros = []
          // if (this.$store.state.fastPessoa.telefone) {
          //   if (!this.validaTelefone(this.$store.state.fastPessoa.telefone)) erros.push("O telefone está no formato incorreto")
          // } 
          if (this.$store.state.fastPessoa.datanascimento_abertura) {
            let objDataNasc = new Date(`${this.$store.state.fastPessoa.datanascimento_abertura}T00:00:00`)
            let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
            if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
          }
          if (this.$store.state.fastPessoa.cpf_cnpj) {
            if (!this.validaCpfCnpj(this.$store.state.fastPessoa.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
          }      
          if (this.$store.state.fastPessoa.cep) {
            if (!/^(?:\d{8}|\d{5}|\d{9}|\d{7})$/.test(this.$store.state.fastPessoa.cep.replace("-", ""))) erros.push("CEP inválido")
          }     

          if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.$store.state.fastPessoa.email)) erros.push("Email em formato inválido")

          return erros   
        })
        // Atualização
        .then(erros => {        
          if (erros.length) {
            erros.forEach(e =>  this.exibeToasty(e, "error"))
          } else {  
            const modelCadastroUsuario = {
              fast_pessoa: {
                id_usuario: this.$store.state.fastPessoa.id_usuario,
                id_pessoa: this.$store.state.fastPessoa.id_pessoa,
                nome_razao: this.$store.state.fastPessoa.nome_razao,
                sobrenome_fantasia: this.$store.state.fastPessoa.sobrenome_fantasia,
                id_pais: this.$store.state.fastPessoa.id_pais_pessoa,
                cpf_cnpj: this.$store.state.fastPessoa.cpf_cnpj,
                datanascimento_abertura: this.$store.state.fastPessoa.datanascimento_abertura,
                sexo: this.$store.state.fastPessoa.sexo,
                id_plataforma: this.$route.params.id_plataforma,
                naturalidade: this.$store.state.fastPessoa.naturalidade,
                rg: this.$store.state.fastPessoa.rg,
                orgao_emissor: this.$store.state.fastPessoa.orgao_emissor,
                payee_code_gerencianet: ""
              },
              fast_usuario: {
                id_usuario: this.$store.state.fastPessoa.id_usuario,
                email: this.$store.state.fastPessoa.email
              },
              fast_pessoa_endereco: {
                id_pais: this.$store.state.fastPessoa.id_pais_endereco,
                uf: this.$store.state.fastPessoa.uf,
                cep: this.$store.state.fastPessoa.cep,
                logradouro: this.$store.state.fastPessoa.logradouro,
                bairro: this.$store.state.fastPessoa.bairro,
                cidade: this.$store.state.fastPessoa.cidade,
                complemento: this.$store.state.fastPessoa.complemento,
                numero: this.$store.state.fastPessoa.numero,
              },
              fast_pessoa_telefone: {
                id_pais: this.$store.state.fastPessoa.id_pais_telefone,
                telefone: this.$store.state.fastPessoa.telefone,
              },
              id_plataforma: this.$route.params.id_plataforma
            };

            this.$store.state.fastCarregando = true;
            this.promisePostFastApi(modelCadastroUsuario, "api/fast_plataforma_usuario/atualiza_pessoa_secretaria_novo")
            .then(obj => {              
              console.log(obj)  
              if (obj.id_pessoa){
                
                //let obj = JSON.parse(res);
                if (!obj.error) {
                  if (obj.datanascimento_abertura)
                    obj.datanascimento_abertura = obj.datanascimento_abertura.split(
                      "T"
                    )[0];
                  else obj.datanascimento_abertura = "";
                  if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
                  if (!obj.cep) obj.cep = "";
                  if (!obj.cidade) obj.cidade = "";
                  if (!obj.complemento) obj.complemento = "";
                  if (!obj.id_pais_endereco) obj.id_pais_endereco = "";
                  if (!obj.id_pais_pessoa) obj.id_pais_pessoa = "";
                  if (!obj.id_pais_telefone) obj.id_pais_telefone = "";
                  if (!obj.logradouro) obj.logradouro = "";
                  if (!obj.numero) obj.numero = "";
                  if (!obj.sexo) obj.sexo = "";
                  if (!obj.telefone) obj.telefone = "";
                  if (!obj.uf) obj.uf = "";
                  if (!obj.bairro) obj.bairro = "";
                  this.$store.state.fastPessoa = obj;

                  this.$store.state.fastPessoa.alteracao_pendente = false;

                  this.exibeToasty("Perfil alterado com sucesso", "success")   

                } else {
                  this.exibeToasty(`Erro ao salvar alterações`, "error")
                }                          
              } else {
                this.exibeToasty(`Erro ao salvar alterações`, "error")
              }
              this.$store.state.fastCarregando = false
            })
            .catch((e) => {                
              this.exibeToasty(`${e}`, "error")
              this.$store.state.fastCarregando = false
            });             
          }  
        })
        .catch(e => {
          this.$store.state.fastCarregando = false
          this.exibeToasty(e, "error")
        })      
      },
    }
}
</script>
<style scoped>

.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>